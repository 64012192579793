
import { defineComponent } from 'vue'
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import TextArea from "primevue/textarea";
import Dropdown from 'primevue/dropdown';
import {mapGetters, mapActions} from "vuex";
import Log from "@/types/logs";

export default defineComponent({
  name: "AddLogEntryDialog",
  components: {
    Dialog,
    TextArea,
    Button,
    Dropdown,
  },
  props: {
    isSaving: {
      type: Boolean,
      required: true,
    },
  },
  created() {
    this.getLogControl();
  },
  data() {
    return {
      newLog: {
        subject: '',
        details: '',
        type: ''
      } as Log,
      isSubmitted: false,
      isVisible: false,
    }
  },
  computed: {
    ...mapGetters({
      getContactLog: "customerInquiry/getContactLog",
      getLogTypes: "logControl/getTypes"
    }),
  },
  methods: {
    ...mapActions({
      getLogControl: "logControl/getLogControl"
    }),
    openNewLogDialog() {
      this.isVisible = true;
    },
    closeNewLogDialog() {
      this.isVisible = false;
      this.isSubmitted = false;
      this.newLog = { subject: '', details: ''} as Log;
    },
    saveLogInfo() {
      this.isSubmitted = true;
      if (this.newLog.subject) {
        this.$emit('addLog', this.newLog);
        this.closeNewLogDialog();
      }
    }
  }
})
