
  import { defineComponent } from "vue";
  import { mapGetters } from "vuex";

  // Components
  import AutoComplete from 'primevue/autocomplete';

  import SalesService from "@/services/SalesService";
  const salesService = new SalesService();

  // Types
  interface Data {
    query: string,
    suggestions: string[],
  }
  
  export default defineComponent({
    name: "OrderAutocomplete",
    components: {
      AutoComplete,
    },
    emits: [
      "item-select",
    ],
    props: {
      selectedId: {
        type: String,
        required:false,
        default: "",
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data(): Data {
      return {
        query: "",
        suggestions: [],
      }
    },
    watch: {
      selectedId() {
        this.getOrderById()
      },
    },
    computed: {
      ...mapGetters({
        getClient: "session/getClient",
      }),
      suggestionsIds() {
        return this.suggestions.map((suggestion: any) => suggestion.so_id);
      },
    },
    methods: {
      handleComplete() {
        salesService.getOrderById(this.query, 'cust_name')
          .then((response: any) => {
            if (response) {
              this.suggestions = [response];}
          })
      },
      handleItemSelect(event: any) {
        const selected = this.suggestions.find((suggestion: any) => suggestion.so_id === event.value);
        this.$emit("item-select", selected);
      },
      getOrderById() {
        if (this.selectedId) {
          salesService.getOrderById(this.selectedId, 'cust_name')
          .then((response: any) => {
            if (response) {
              this.query = response.so_id;
            }
          });
        }
      },
    },
    mounted() {
      this.getOrderById();
    }
  });
  